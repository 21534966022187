import React from 'react';
import PropTypes from 'prop-types';
import { useLottie } from "lottie-react";
import loading from './loading.json';
import failure from './failure.json';
import { STATUS } from '../../context/MagicSvgContext/magicSvgReducer';

const ANIMATIONS_MAP = {
  [STATUS.LOADING]: loading,
  [STATUS.FAILED]: failure,
};

export default function MagicSvgAnimation({ animationKey }) {
  const animationData = ANIMATIONS_MAP[animationKey] || ANIMATIONS_MAP.loading;
  const { View } = useLottie({ animationData, loop: true });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{View}</>;
}

MagicSvgAnimation.propTypes = {
  animationKey: PropTypes.string.isRequired,
};