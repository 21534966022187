/**
 * Below mocks are used when the page is in editing mode in the builder editor
 * Builder editor does not have the ability to make authenticated requests
 */
export const MOCK_TOKENS = 40;

export const MOCK_RECENT_IMAGES_DATA = [
  {
    "createdAt": "2024-07-10T21:45:40.756Z",
    "additionalFields": {
      userText: 'blah',
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Ff4fd9459-107e-478e-bba9-31ee550abf15%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Ff4fd9459-107e-478e-bba9-31ee550abf15%2Ftraced.svg",
    "id": "f4fd9459-107e-478e-bba9-31ee550abf15",
    "artStyle": "generated-image-grownup-coloring-book-vector-text",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "cute otters eating seeds"
  },
  {
    "createdAt": "2024-07-10T21:35:36.169Z",
    "additionalFields": {
      userText: 'blah',
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Faa50fa7f-f3ae-4f0c-abbe-83e0adfb3f9d%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Faa50fa7f-f3ae-4f0c-abbe-83e0adfb3f9d%2Ftraced.svg",
    "id": "aa50fa7f-f3ae-4f0c-abbe-83e0adfb3f9d",
    "artStyle": "generated-image-sticker-2",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "pikachu"
  },
  {
    "createdAt": "2024-07-10T21:34:40.457Z",
    "additionalFields": {
      userText: 'blah',
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F4e581799-d4e9-4769-bdb3-188b4cd84f0c%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F4e581799-d4e9-4769-bdb3-188b4cd84f0c%2Ftraced.svg",
    "id": "4e581799-d4e9-4769-bdb3-188b4cd84f0c",
    "artStyle": "generated-image-icon-vector",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "bunny riding a dragon"
  },
  {
    "createdAt": "2024-07-03T20:34:27.292Z",
    "additionalFields": {
      userText: 'blah',
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F5b08974f-c9fa-49bf-a686-6938d4a7252f%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F5b08974f-c9fa-49bf-a686-6938d4a7252f%2Ftraced.svg",
    "id": "5b08974f-c9fa-49bf-a686-6938d4a7252f",
    "artStyle": "generated-image-grownup-coloring-book-vector",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "wine glass"
  }
];

export const MOCK_IMAGE = {
  "createdAt": "2024-07-10T21:45:40.756Z",
  "additionalFields": {},
  "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Ff4fd9459-107e-478e-bba9-31ee550abf15%2Fraw.png",
  "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Ff4fd9459-107e-478e-bba9-31ee550abf15%2Ftraced.svg",
  "id": "f4fd9459-107e-478e-bba9-31ee550abf15",
  "artStyle": "generated-image-grownup-coloring-book-vector",
  "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
  "prompt": "cute otters eating seeds"
};

export const USER_ACTIONS = {
  REGENERATE: 'regenerate',
  SCRAP: 'scrap',
  DELIVER: 'deliver',
};

export const IMAGE_STATUS = {
  DELETED: 'deleted',
  DELIVERED: 'delivered',
};